import { ResourcesState } from "@/interfaces/global/interfaces";
import { MutationTree } from "vuex";

const mutations: MutationTree<ResourcesState> = {
  setDocumentTypes(state: ResourcesState, data: object[]) {
    state.documentTypes = data;
  },

  setUsersSystem(
    state: ResourcesState,
    data: { value: number; label: string; document: string }[]
  ) {
    state.user_registered = [];
    state.user_registered = data.map((user) => ({
      label: user.label,
      value: user.value,
    }));
  },
};

export default mutations;
